<template>
    <div class="hero-part">
        <div class="inner">
            <div class="content">
                <h1>Professional, Personalised Legal Services.</h1>
                <p>You have come to the right firm</p>
                <img id="arrow"
                    src="https://cdn3.iconfinder.com/data/icons/google-material-design-icons/48/ic_keyboard_arrow_down_48px-128.png">
            </div>
        </div>
    </div>
</template>