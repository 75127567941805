<template>
    <div id="services">
        <pageHeader />
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-sm-8">
                        <div class="desc">
                            <h4>Client Connected. Solution driven.</h4>
                            <p>
                                If you are looking for unique and creative solutions to your legal problems, <em>you
                                    have come to the right firm.</em> Our attorneys strive to find strategic angles in
                                every
                                matter, to give their clients the highest quality legal services, and the best outcome.
                                We
                                strive to connect with our clients and get the best solution fro their problems.
                            </p>
                            <img src="@/assets/img/img-1.jpg" alt="hello">
                        </div>
                    </div>
                    <div class="col-sm-4 w3-hide-small">
                        <div class="service-list">
                            <h4>Legal Practices</h4>
                            <ul class="list-unstyled">
                                <li>Conveyancing &amp; Property Law</li>
                                <li>Criminal Law</li>
                                <li>RAF Claims</li>
                                <li>Deceased Estates &amp; Wills</li>
                                <li>Family Law</li>
                                <li>Uncontested Divorce</li>
                                <li>Labour Law</li>
                                <li>Contact Law</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="pactices-list">
                    <div class="header">
                        <h3>Fully Detailed List of Practices</h3>
                        <p>our legal service includes, but are not limited to, the following list:</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <h4>Criminal Law</h4>
                            <p>
                                We understand the adverse consequences that an arrest or a criminal charge may have on
                                you
                                and your family. We endeavour to provide professional legal representation and support
                                in
                                order to mitigate such consequences to the best of our ability.
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Deceased Estates &amp; Wills</h4>
                            <p>
                                We cut through the red tape and assist you winding up the estate of a loved one
                                according to
                                their wishes in the will and other applicable laws.
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Road Accident Fund Claims</h4>
                            <p>
                                Were you or a loved one involved in a road accident? We assist people from all walks of
                                life
                                with road accident fund claims.
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Family Law</h4>
                            <p>
                                We bring our extensive legal experience to assist you on issues that affect you on the
                                home
                                front, from entering into a new marriage or domestic partnership or having a child.
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Commercial &amp; Corporate Law</h4>
                            <p>
                                Assistance in analysing the current business status and exploring solutions to return
                                the
                                business to profitable trading or winding up the business in an orderly manner
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Conveyancing &amp; Property Law</h4>
                            <p>
                                We pride ourselves in guiding clients through the process of transferring ownership of
                                any immovable property (real estate), every stpeep of the way. We feel it is vital for
                                property owners to know and understand the property transfer and conveyancing process.
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Labour Law</h4>
                            <p>
                                Have you been unfairly dismissed or any unfair labour practices? We can help you in
                                taking the action and protect your rights and we will handle your case before the CCMA
                                and labour court
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Contract Law</h4>
                            <p>
                                Contracts are essentail tools in managing positive and constructive relationships
                                between all the parties involved. It is, therefore, advisable to have a professinally
                                drafted agreement to avoid any negative consequences.
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <h4>Debt Collection</h4>
                            <p>
                                We offer both commercial and personal debt collection services, at highly competitive
                                collection rates. Low cost, greatest success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <interested />
        </div>
    </div>
</template>

<script>
    import pageHeader from "../components/PageHeader";
    import interested from "../components/Interested";
    export default {
        components: {
            pageHeader,
            interested
        },
    };
</script>