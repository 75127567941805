<template>
    <div id="page_footer">
        <div class="top-part">
            <div class="container">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class=""></i>Contact</h4>
                        <ul class="list-unstyled">
                            <li><a href="tel:+27397272929">039 727 2929</a></li>
                            <li><a href="tel:+27614942111">061 494 2111</a></li>
                            <li><a href="mailto:info@nondabulaattorneys.co.za">info@nondabulaattorneys.co.za</a></li>
                        </ul>
                        <address>
                            Office 6 Mpiti Building, 98 Main Street, Kokstad, Kwa-Zulu Natal, 4700
                        </address>
                    </div>
                    <div class="col-sm-3">
                        <h4>Quick Links</h4>
                        <ul class="list-unstyled">
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Our Services</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-3">
                        <h4>Practice Areas</h4>
                        <ul class="list-unstyled">
                            <li><a href="#">Criminal Law</a></li>
                            <li><a href="#">Family Law</a></li>
                            <li><a href="#">Deceased Estates &amp; Wills</a></li>
                            <li><a href="#">Road Accident Fund Claims</a></li>
                            <li><a href="#">Commercial &amp; Corporate Law</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <p><span>&copy; {{ new Date().getFullYear() }}</span> Khayalethu Nondabula Attorneys Inc. |
                    <span>Designed
                        by</span> <a href="http://www.infinitogroup.co.za" target="_blank"
                        rel="noopener noreferrer">Infinito Group</a>
                </p>
            </div>
        </div>
    </div>
</template>