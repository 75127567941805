<template>
    <div id="contact">
        <pageHeader />
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <h3>
                            Conatct Info
                        </h3>
                        <div class="contact-info">
                            <div class="info-item">
                                <i class="fa fa-phone"></i>
                                <div>
                                    <h4>Landline Number</h4>
                                    <p>039 727 2929</p>
                                </div>
                            </div>
                            <div class="info-item">
                                <i class="fa fa-mobile"></i>
                                <div>
                                    <h4>Cell Number</h4>
                                    <p>061 494 2111</p>
                                </div>
                            </div>
                            <div class="info-item">
                                <i class="fa fa-envelope"></i>
                                <div>
                                    <h4>Email Address</h4>
                                    <p><a href="mailto:info@nondabulaattorney.co.za">info@nondabulaattorney.co.za</a>
                                    </p>
                                </div>
                            </div>
                            <div class="info-item">
                                <i class="fa fa-map-marker-alt"></i>
                                <div>
                                    <h4>Our Location</h4>
                                    <p>
                                        Office 6 Mpiti Building, 98 Main Street, <br />
                                        Kokstad, Kwa-Zulu Natal, 4700
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h3>
                            Leave a Message
                        </h3>
                        <div class="contact-form">
                            <form action="#" method="post">
                                <input type="text" name="full_name" placeholder="Full Name (e.g. John Doe)" required>
                                <input type="email" name="email" placeholder="Email Address (e.g. email@mail.co.za)"
                                    required>
                                <input type="number" name="contact" placeholder="Contact">
                                <input type=" text" name="message_subject"
                                    placeholder="Subject (e.g. Enquirng about Family Law)">
                                <textarea name="message" placeholder="Message" required></textarea>

                                <button type="submit" class="subtmit-message">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="company-map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3436.0546792500336!2d29.422458714972993!3d-30.547759161828914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef5a070bb29c5ef%3A0x90444e403c655920!2s98%20Main%20St%2C%20Kokstad%2C%204700!5e0!3m2!1sen!2sza!4v1629975190146!5m2!1sen!2sza"
                width="100%" height="500" style="border:0;" allowfullscreen="true" loading="lazy"></iframe>
        </div>
        <interested />
    </div>
</template>

<script>
    import pageHeader from "../components/PageHeader";
    import interested from "../components/Interested";
    export default {
        components: {
            pageHeader,
            interested
        },
    };
</script>