import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import '@popperjs/core'
import './assets/sass/main.scss'
import $ from 'jquery'

window.$ = $

createApp(App).use(store).use(router).mount('#app')

$(window).on('scroll', function () {
    // const initialLogo = '@/assets/img/full-logo.png'
    // const scrollLogo = '@/assets/img/white-logo.png'
    var scroll = $(window).scrollTop();

    if (scroll >= 50) {
        $('#main_navbar').addClass('change-nav');
        $('#main_navbar').addClass('w3-card');
        // $('#main_navbar img').attr('src', scrollLogo);
    } else {
        $('#main_navbar').removeClass('change-nav');
        $('#main_navbar').removeClass('w3-card');
        // $('#main_navbar img').attr('src', initialLogo);
    }
});

$('.navbar-collapse a').on('click', function () {
    $('.navbar-collapse').removeClass('show');
})

$(window).on('click', function () {
    $('.navbar-collapse').removeClass('show');
})
