<template>
	<div id="about">
		<pageHeader />
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-sm-12">
						<div class="row">
							<div class="col-sm-6">
								<div class="vision w3-card">
									<h4>Our Vision</h4>
									<p>
										We aspire to be recognized as the leading black law firm in KwaZulu-Natal that
										renders a high-quality service to our clients. We intend to grow to be a major
										force in the long term, within South Africa and the SADC region.
									</p>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="mission w3-card">
									<h4>Our Mission</h4>
									<p>
										To act ethically with integrity and honesty, thereby becoming a trusted
										advisor to our clients.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 mt-5">
						<div class="row">
							<div class="col-sm-7">
								<div class="full-about">
									<h1>Our History</h1>
									<p>
										The firm was founded in July 2010 out of a necessity for highly skilled,
										experienced and multi-disciplined black law firm in KwaZulu-Natal. Under the
										leadership of one director, Khayalethu Nondabula, the firm is geared
										to providing its clients with efficient legal advice as well as simple solutions
										to their complex and diverse legal matters.

									</p>

									<h4>Our Commitment</h4>
									<p>
										Our commitment is to provide speedy and transparent legal services to the
										members
										of the community at large and to give any kind of legal assistance to government
										departments, municipalities and other institutions.
									</p>

									<h4>Association and Alliances</h4>
									<p>
										The firm is committed to work closely with fellow firms who similarly are
										committed to bridging the gap between highly skilled and experienced law firms
										with disadvantaged small black firms to ensure transfer of skills and expertise
										in a meaningful way. We believe that this can be achieved without compromising
										the firm’s excellent service standards.
									</p>

									<h4>Capacity of the Firm</h4>
									<p>
										Our firm has a highly efficient professional team and support staff. Due to the
										size of our firm, we are able to stay closer to our clients and take decisions
										much quicker without compromising value to our client. We have in the past taken
										on consultants to assist in Projects where we lacked in capacity. We shall also
										ensure that inversed capacity is achieved where we experience increased volume
										of work.
									</p>
								</div>
							</div>
							<div class="col-sm-5">
								<div class="about-image w3-hide-small"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<interested />
		</div>
	</div>
</template>

<script>
	import pageHeader from "../components/PageHeader";
	import interested from "../components/Interested";
	export default {
		components: {
			pageHeader,
			interested
		},
	};
</script>