<template>
    <div class="insterested">
        <div class="inner">
            <h4>Are you interested?</h4>
            <p>If you interested in any of our legal services or have a query</p>
            <router-link @click="scrollToTop()" to="/contact">Contact Us Now</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0)
            }
        }
    }
</script>