<template>
    <nav class="navbar navbar-expand-lg fixed-top" id="main_navbar">
        <div class="container-fluid">
            <router-link @click="scrollToTop()" class="navbar-brand" to="/">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fa fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto flex-nowrap">
                    <li class="nav-item">
                        <router-link @click="scrollToTop()" class="nav-link active" aria-current="page" to="/">Home
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link @click="scrollToTop()" class="nav-link" to="/about">About</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link @click="scrollToTop()" class="nav-link" to="/practice-areas">Practice Areas
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link @click="scrollToTop()" class="nav-link" to="/contact">Contact</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    export default {
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0)
            }
        }
    }
</script>