<template>
  <loader v-if="isLoading" />
  <navbarNav />
  <router-view />
  <page-footer />
</template>

<script>
  import { ref, watch } from 'vue'
  import router from './router'
  import navbarNav from "./components/Navigation";
  import pageFooter from "./components/Footer"
  import loader from "./components/Loader"
  export default {
    components: {
      navbarNav,
      pageFooter,
      loader
    },

    setup() {
      const isLoading = ref(true)
      watch(router.currentRoute, () => {
        isLoading.value = true
        resetLoad()
      })

      function resetLoad() {
        setTimeout(() => {
          isLoading.value = false
        }, 4000);
      }

      resetLoad()

      return {
        isLoading
      }
    }
  };
</script>