<template>
	<div id="home_page">
		<HeroPart />
		<div class="brief-about">
			<div class="container">
				<div class="row">
					<div class="col-sm-9 mx-auto text-center">
						<h4 class="brief-intro">
							We are a law that is based Kokstad and specializes in a wide range of legal areas.
						</h4>
					</div>
				</div>
				<div class="row brief-desc">
					<div class="col-12">
						<h1>Affordable Legal Advices</h1>
					</div>
					<div class="col-sm-6">
						<p>
							Khayalethu Nondabula Attorneys Inc. is a Kokstad based law firm committed to providing
							individuals and
							families with the
							best
							possible legal help and advice when it is needed most. We work hard to understand you, your
							family and
							your
							business so that we can work together to meet your changing life goals and interests.
						</p>
					</div>
					<div class="col-sm-6">
						<p>The firm’s practice areas include estate planning, wills and estate administration,
							residential real
							estate
							transactions, conveyancing and family law catering to both heterosexual and same-sex
							families. We
							are a small
							law
							firm offering great personal service, unparalleled focus and commitment to our clients at
							very competitive
							rates.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="our-services">
			<div class="container">
				<h1>We offer a variety of lagal practices</h1>
				<div class="row">
					<div class="col-sm-4">
						<h4>Criminal Law</h4>
						<p>
							We understand the adverse consequences that an arrest or a criminal charge may have on you
							and your family. We endeavour to provide professional legal representation and support in
							order to mitigate such consequences to the best of our ability.
						</p>
					</div>
					<div class="col-sm-4">
						<h4>Deceased Estates &amp; Wills</h4>
						<p>
							We cut through the red tape and assist you winding up the estate of a loved one according to
							their wishes in the will and other applicable laws.
						</p>
					</div>
					<div class="col-sm-4">
						<h4>Road Accident Fund Claims</h4>
						<p>
							Were you or a loved one involved in a road accident? We assist people from all walks of life
							with road accident fund claims.
						</p>
					</div>
					<div class="col-sm-4">
						<h4>Family Law</h4>
						<p>
							We bring our extensive legal experience to assist you on issues that affect you on the home
							front, from entering into a new marriage or domestic partnership or having a child.
						</p>
					</div>
					<div class="col-sm-4">
						<h4>Commercial &amp; Corporate Law</h4>
						<p>
							Assistance in analysing the current business status and exploring solutions to return the
							business to profitable trading or winding up the business in an orderly manner
						</p>
					</div>
					<div class="col-sm-4">
						<router-link to="/practice-areas" class="view-services">
							More Areas
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<interested />
	</div>
</template>

<script>
	import HeroPart from "../components/Hero";
	import interested from "../components/Interested"
	export default {
		components: {
			HeroPart,
			interested,
		},
	};
</script>