<template>
    <div class="page-header" v-if="header != null">
        <div class="inner">
            <div class="content">
                <div class="container">
                    <!-- <img src="../assets/img/logo.png" alt="" srcset=""> -->
                    <h1 v-if="header.title != ''">{{header.title}}</h1>
                    <span></span>
                    <p v-if="header.caption != ''">{{header.caption}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '../router'
    import headerData from "../data/pageHeaderContent.json"
    import { reactive, watch, ref } from 'vue';
    export default {
        setup() {
            const routerName = ref(router.currentRoute.value.name)
            const header = reactive({
                title: '',
                caption: ''
            })

            watch(router.currentRoute, (to) => {
                routerName.value = to.name
                switch (routerName.value) {
                    case 'About':
                    case 'Services':
                    case 'Contact':
                        routerChange()
                        break
                }
            })
            routerChange()
            function routerChange() {
                const { title, caption } = headerData[routerName.value]
                header.title = title
                header.caption = caption
            }
            return {
                header
            }
        }
    }
</script>